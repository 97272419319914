// Toast - https://web.dev/building-a-toast-component
import { useEffect, useRef } from 'react';
import styles from './Toast.module.sass';

const useToast = () => {
  const toastRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (document) init();
  }, []);

  const init = () => {
    const toastId = 'toast-section';
    const toastSection = document.getElementById(toastId);

    if (toastSection) {
      toastRef.current = toastSection;
    } else {
      const node = document.createElement('section');

      node.classList.add(
        styles['gui-toast-group'],
        'text-size-small',
        'text-lineheight-xshort'
      );

      node.id = toastId;
      document.firstElementChild!.insertBefore(node, document.body);
      toastRef.current = node;
    }
  };

  const createToast = (text: string) => {
    const node = document.createElement('output');

    node.innerText = text;
    node.classList.add(styles['gui-toast']);
    node.setAttribute('role', 'status');
    node.setAttribute('aria-live', 'polite');

    return node;
  };

  const addToast = (toast: HTMLOutputElement) => {
    if (toastRef.current) {
      toastRef.current.appendChild(toast);
    }
  };

  const triggerToast = (text: string) => {
    const toast = createToast(text);

    addToast(toast);

    return new Promise(async (resolve, _reject) => {
      await Promise.allSettled(
        toast.getAnimations().map((animation) => animation.finished)
      );

      if (toastRef.current) {
        toastRef.current.removeChild(toast);
      }

      resolve('removed');
    });
  };
  return { triggerToast };
};

export default useToast;
